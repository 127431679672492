import { getCookieOrCreate } from './Cookie';
import {
    keysToCamelFromSnake,
    keysToSnakeFromCamel,
} from './caseconverters';

const parseJSON = (response) => {
    if (response.status === 204) {
        return null;
    }
    return response.json();
}

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const buildHeaders = () => {
    return {
        ...defaultHeaders,
    };
};

const buildHeadersWithCsrf = async () => {
    const crsftoken = await getCookieOrCreate('csrftoken');
    return {
        'X-CSRFToken': crsftoken,
        ...defaultHeaders,
    }
}

const checkStatus = (response) => {
    // 400 is for bad request when login credentials are wrong
    if ((response.status >= 200 && response.status < 300)) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};


const httpGet = async (url) => 
    fetch(url, {
        headers: buildHeaders(),
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(parseJSON);

const httpGetWithCsrf = async (url) => 
    fetch(url, {
        headers: await buildHeadersWithCsrf(),
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(parseJSON);

const httpPostWithCsrfToken = async (url, data, method = 'post') => {
    return await fetch(url, {
        method: method,
        headers: await buildHeadersWithCsrf(),
        body: JSON.stringify(keysToSnakeFromCamel(data)),
        credentials: 'same-origin',
    }).then(checkStatus).then(parseJSON);
}

const httpPost = (url, data) =>
    fetch(url, {
        method: 'post',
        headers: buildHeaders(),
        body: JSON.stringify(data),
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(parseJSON);

export { httpPostWithCsrfToken, httpGet, httpGetWithCsrf, httpPost };
